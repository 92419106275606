@use '../mixins.scss' as *;

.repo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  font-family: 'Apfel', sans-serif;
  overflow: hidden;
  .repoInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
    header {
      font-family: 'Apfel';
      padding: 8px 8px 0 8px;
      h1 {
        @include titileMobile;
      }
      .short {
        @include descriptionMobile;
      }
      .githubLink {
        display: none;
      }
    }
  }
  .projects {
    width: 100%;
    height: 70%;
    overflow-x: scroll;
    .wrapper {
      display: flex;
      justify-content: space-around;
      width: 500vw;
      height: 100%;
      align-items: flex-start;
    }
  }
}

@media (min-width: 600px) {
  .repo {
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    text-align: center;
    font-family: 'Apfel', sans-serif;
    .repoInfo {
      width: 50%;
      height: 100%;
      justify-content: center;
      align-items: center;

      header {
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 0;
        width: 100%;
        padding: 0 4vw 0 4vw;
        h1 {
          @include titileDesktop;
        }
        .short {
          @include descriptionDesktop;
          transform: translate(0, -10%);
        }
        .githubLink {
          @include customDesktopButton;
          position: static;
          font-weight: bold;
          transform: none;
          width: 15vw;
          height: 4vw;
          font-size: 1.2vw;
          margin-top: 2vw;
          margin-left: 0;
        }
      }
    }
    .projects {
      width: 50%;
      height: 100%;
      overflow-x: auto;
      .wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 300vh;
      }
      .scrollbar-thumb {
        width: 4px;
        background: rgba(0, 0, 0, 0.114);
      }
      .scrollbar-track-y {
        right: 10px;
        width: 4px;
      }
    }
  }
}
