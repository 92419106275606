.moreInfo {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  font-size: 40px;
  font-family: 'Apfel', sans-serif;
  overflow: hidden;

  .formContainer {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    form {
      display: flex;
      padding-top: 5vw;
      flex-direction: column;
      width: 90%;
    }
    .success {
      font-size: 4vw;
      color: green;
      width: 90%;
      text-align: left;
      padding-top: 0.6vw;
      margin-left: 1vw;
    }
    .fail {
      font-size: 4vw;
      color: red;
      width: 90%;
      text-align: left;
      padding-top: 0.6vw;
      margin-left: 1vw;
    }
  }
}

@media (min-width: 600px) {
  .moreInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    font-size: 40px;
    text-align: center;
    font-family: 'Apfel', sans-serif;
    .formContainer {
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      form {
        display: flex;
        padding-top: 0;
        flex-direction: column;
        width: 70%;
      }
      .success {
        font-size: 1vw;
        width: 70%;
        padding-top: 0.3vw;
      }
      .fail {
        font-size: 1vw;
        width: 70%;
        padding-top: 0.3vw;
      }
    }
  }
}
