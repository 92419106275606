.play {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  width: 24vw;
  height: 24vw;
  border-radius: 200px;
  background: linear-gradient(
    0deg,
    rgba(217, 217, 217, 0.75) 0%,
    rgba(217, 217, 217, 0.25) 100%
  );
  filter: drop-shadow(0px 2px rgba(0, 0, 0, 0.25));
  offset: 0 2px rgba(0, 0, 0, 0.25);
  .icon {
    background-image: url(./Polygon.png);
    width: 48%;
    height: 48%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(14%, 2%);
    background-color: transparent;
  }
}
@media (min-width: 600px) {
  .play {
    width: 7vw;
    height: 7vw;
    .icon {
      width: 38%;
      height: 38%;
    }
  }
}
