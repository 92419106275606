@use '../mixins.scss' as *;

.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  height: 100vh;
  width: 100vw;
  font-family: 'Apfel', sans-serif;
  .skillsSetup {
    display: flex;
    width: 100%;
    height: 80%;
    font-family: 'ApfelFett', sans-serif;
    padding: 4vw 3vw;
  }
}
.skillsInfo {
  display: flex;
  // justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  font-weight: 400;
  header {
    margin-bottom: 0;
    width: 100%;
    padding: 8px 8px 0 8px;
    h1 {
      @include titileMobile;
    }
    .short {
      @include descriptionMobile;
    }
  }
}

@media (min-width: 600px) {
  .skills {
    flex-direction: row-reverse;
    .skillsSetup {
      width: 50%;
      height: 100%;
    }
  }
  .skillsInfo {
    width: 50%;
    height: 100%;
    header {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      padding: 0vw 4vw 0vw 4vw;
      h1 {
        @include titileDesktop;
      }
      .short {
        @include descriptionDesktop;
        transform: translate(0, -1.5vw);
      }
    }
  }
}
