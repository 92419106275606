@use '../animations.scss' as *;

.hero {
  position: relative;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  font-size: 40px;
  font-family: 'Apfel';
  .circleNav {
    position: absolute;
    top: 2%;
    left: 0;
    right: 0;
    ul {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 5vw;
      list-style: none;
      li {
        button {
          display: block;
          font-family: 'Apfel';
          overflow: hidden;
          width: 8vw;
          height: 8vw;
          background-color: #424242;
          color: #424242;
          border-radius: 200px;
          transition: 0.3s;
          border: none;
          font-size: 1.5vw;
          &:hover {
            color: #424242;
          }
        }
      }
    }
  }
  .generalInfo {
    position: absolute;
    top: 40%;
    left: 11%;
    right: 0%;
    h1 {
      font-size: 8vw;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: -0.28vw;
      transform: translate(-5%, 0);
    }
    h2 {
      font-size: 8vw;
      font-weight: 800;
      letter-spacing: -0.28vw;
      font-family: 'ApfelFett';
      text-transform: uppercase;
      color: #a0a0a0;
    }
    .description {
      color: #a0a0a0;
      font-size: 4vw;
      max-width: 75%;
      font-weight: 400;
      line-height: 140%;
    }
  }
}

@media (min-width: 600px) {
  .hero {
    position: relative;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    font-size: 40px;
    font-family: 'Apfel';
    .circleNav {
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      ul {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: flex-start;
        gap: 6vw;
        list-style: none;
        li {
          button {
            display: block;
            font-family: 'Apfel';
            width: 3.5vw;
            height: 3.5vw;
            background-color: #a0a0a0;
            color: #a0a0a0;
            border-radius: 200px;
            transition: 0.3s;
            border: none;
            font-size: 1.4vw;
            overflow: hidden;
            &:not(.toggle):hover {
              color: white;
              width: 15vw;
              background-color: #424242;
            }
          }
          .toggle:hover {
            color: white;
            background-color: #424242;
          }
        }
      }
    }
    .generalInfo {
      position: relative;
      top: 36%;
      left: 11%;
      right: 0%;
      h1 {
        font-size: 4.5vw;
        text-transform: none;
        font-weight: 400;
        letter-spacing: 0;
        transform: none;
        font-size: 6vw;
        letter-spacing: -0.07vw;
        font-family: 'ApfelFett';
        font-weight: 400;
        text-align: left;
        padding-top: 0;
      }
      h2 {
        font-size: 3vw;
        font-weight: 400;
        font-size: 4.5vw;
        letter-spacing: 0;
        font-family: 'Apfel';
        text-transform: none;
        transform: translate(0, -10%);
      }

      .description {
        color: #a0a0a0;
        font-size: 1.11vw;
        max-width: 37%;
        font-weight: 400;
        line-height: 140%;
        span {
          color: #a0a0a0;
          position: relative;
          display: inline-block;
          transition: 0.3s;
          .line {
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 2px;
            left: 0;
            background-color: black;
          }
        }
      }
    }
  }
}
