.mainSkillsNav {
  width: 100%;
  .mainSkillsList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .generalSkillLi {
      position: relative;
      max-height: 9vw;
      transition: 0.5s;
      font-size: 7.5vw;
      line-height: 8.5vw;
      overflow: hidden;
      margin: 1.5vw 0;
      p {
        width: 100%;
        position: relative;

        span {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 15%;
          transition: 0.3s;
          right: 3%;
          font-size: 5vw;
        }
        .turnRound {
          transform: rotate(180deg);
        }
        &::after {
          display: block;
          content: '';
          width: 100%;
          height: 2px;
          background-color: #cbcbcb;
        }
      }
      nav {
        padding: 2.5vw 0 0 7vw;
        ul {
          li {
            font-size: 4.5vw;
            line-height: 6.4vw;
            font-family: 'Apfel', sans-serif;
          }
        }
      }
    }
    .growLi {
      max-height: 80%;
    }
  }
}

@media (min-width: 600px) {
  .mainSkillsNav {
    .mainSkillsList {
      justify-content: center;
      .generalSkillLi {
        max-height: 3.5vw;
        font-size: 2.5vw;
        line-height: 3vw;
        margin: 0;
        p {
          span {
            top: 20%;
            right: 3%;
            font-size: 2vw;
          }
        }
        nav {
          padding: 0.4vw 0 0 3vw;
          ul {
            li {
              font-size: 1.5vw;
              line-height: 2vw;
            }
          }
        }
      }
      .growLi {
        max-height: 100%;
      }
    }
  }
}
