.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12px 12px;
  width: 15%;
  border-radius: 24px;
  background: #f0f0f0;
  .poster {
    position: relative;
    height: 190px;
    width: 100%;
    background: #1f213a;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid black;
    transition: 0.3s;
  }
  .generalInfo {
    width: 100%;
    border-radius: 12px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .wrapperInfo {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 2% 0;
      .projectTitle {
        font-family: 'ApfelFett';
        font-size: 7vw;
      }
      .description {
        margin-top: 5px;
        font-size: 3.7vw;
        color: #828282;
      }
    }
  }
}

@media (min-width: 600px) {
  .card {
    width: 70%;
    border-radius: 24px;
    height: auto;
    @for $i from 1 through 5 {
      &:nth-of-type(#{$i}) {
        .graphics {
          background-image: url(../../../assets/posters/#{$i}.jpg);
        }
      }
    }
    .poster {
      background: #1f213a;
      height: 260px;
      width: 100%;
      border: 1px solid black;
      .graphics {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        filter: blur(50px) brightness(0.7);
        background-position: center;
        background-size: cover;
        transition: 0.5s;
      }
      &:hover {
        .graphics {
          filter: blur(0);
        }
      }
    }
    .generalInfo {
      width: 100%;
      border-radius: 12px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .wrapperInfo {
        .projectTitle {
          font-family: 'ApfelFett';
          font-size: 2.5vw;
        }
        .description {
          display: flex;
          font-size: 1vw;
          max-width: 90%;
        }
      }
    }
  }
}
