* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

@font-face {
  font-family: 'Apfel';
  src: url('./fonts/Apfel.woff') format('woff'),
    url('./fonts/Apfel.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ApfelFett';
  src: url('./fonts/ApfelFett.woff') format('woff'),
    url('./fonts/ApfelFett.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@keyframes showApp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.App {
  opacity: 0;
  animation: showApp 0.25s linear 0.25s forwards;
  transition: 0.4s;
  #topArrow {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 15px;
    right: 3.7%;
    width: 10vw;
    height: 10vw;
    border-radius: 200px;
    background-color: #e9e9e994;
    animation: visibility 4s ease forwards;
    svg {
      background-color: transparent;
    }
  }
  @media (min-width: 600px) {
    #topArrow {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 25px;
      font-size: 12px;
    }
  }
}

.darkmood {
  .ns-container {
    background-color: rgb(23, 23, 23);
  }
  .scrollbar-track {
    background: rgba(222, 222, 222, 0.15);
  }
  .scrollbar-thumb {
    background: rgba(222, 222, 222, 0.95) !important;
  }
  .hero {
    .circleNav ul li button {
      background-color: #424242;
      box-shadow: 0px 0px 60px 2px rgb(112, 114, 149);
      color: #424242;
      &:not(.toggle):hover {
        color: #424242;
        width: 15vw;
        background-color: #cbcbcb;
      }
    }
    .generalInfo {
      .description {
        span {
          color: #a0a0a0;
          .line {
            background-color: white;
          }
        }
      }
    }
    .circleVideo {
      border: 2px solid white;
    }
  }
  .link {
    p {
      &::after {
        background: #e9e9e994 !important;
      }
    }
    header {
      .short {
        color: #e9e9e994;
      }
    }
    .generalSkillLi {
      li {
        color: #e9e9e994;
      }
    }
  }
  .projects {
    .card {
      background: #0b0b0b !important;
    }
    .githubLink {
      color: #e9e9e994 !important;
    }
  }
  .card {
    .gradient {
      background-color: #0f101d;
    }
    .generalInfo .wrapperInfo {
      .description {
        color: #e9e9e994;
      }
    }
  }
  background-color: rgb(23, 23, 23);
  color: white;
}

@media (prefers-color-scheme: dark) {
  body::after {
    content: 'dark';
    display: none;
  }
}

@media (prefers-color-scheme: light) {
  body::after {
    content: 'light';
    display: none;
  }
}
