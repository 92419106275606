footer {
  width: 100%;
  height: 15vh;
  background-color: rgba(46, 46, 46, 1);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-family: 'Apfel';
  color: white;
  font-size: 3vw;
  .socialWrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    a {
      text-decoration: none;
      text-transform: none;
      font-size: 7vw;
      color: white;
      width: 20%;
      text-align: center;
    }
  }
}

@media (min-width: 600px) {
  footer {
    width: 100%;
    height: 15vh;
    font-size: 1vw;
    .socialWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      a {
        text-decoration: none;
        text-transform: none;
        font-size: 2vw;
        color: white;
        width: 10%;
        text-align: center;
      }
    }
  }
}
