@use '../../mixins.scss' as *;

.basicInfo {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  header {
    padding: 8px 8px 0 8px;
    h1 {
      @include titileMobile;
    }
    h2 {
      @include descriptionMobile;
    }
  }
}

@media (min-width: 600px) {
  .basicInfo {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    padding: 0 4vw 0 4vw;
    header {
      width: 100%;
      height: 40%;
      h1 {
        @include titileDesktop;
        width: 100%;
      }
      h2 {
        @include descriptionDesktop;
        padding-top: 0;
      }
    }
  }
}
