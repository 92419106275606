@keyframes stroke {
  0% {
    fill: rgb(255, 255, 255);
    stroke: rgb(0, 0, 0);
    stroke-dashoffset: 0%;
    stroke-dasharray: 0 50%;
    stroke-width: 0.5;
  }
  70% {
    fill: rgb(255, 255, 255);
    stroke: rgb(0, 0, 0);
    stroke-width: 1;
  }
  100% {
    fill: rgb(0, 0, 0);
    stroke: rgb(0, 0, 0);
    stroke-dashoffset: -20%;
    stroke-dasharray: 40% 0;
    stroke-width: 0;
  }
}

@keyframes strokeMobile {
  0% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgb(0, 0, 0);
    stroke-dashoffset: 0%;
    stroke-dasharray: 0 50%;
    stroke-width: 0.2;
  }
  80% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgb(0, 0, 0);
    stroke-width: 0.4;
  }
  100% {
    fill: rgb(0, 0, 0);
    stroke: rgb(133, 133, 133);
    stroke-dashoffset: -50%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

@keyframes visibility {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
